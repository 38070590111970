import { formatDate } from '@angular/common';
import { Injectable } from '@angular/core';
import { NotificationType } from '@index/enums';
import { Notification } from '@index/interfaces';

import { Adapter } from './adapter';
import { GthBadgeModel } from './badge';
import { GthConversationModel } from './conversation';
import { GthEventItemModel } from './event-item';
import { GthModel } from './model';
import { GthTeamModel } from './team';
import { GthUserModel } from './user';

class TextBuilder {
  private text = '';
  private subtext = '';

  constructor(metaData: NotificationMetadata | undefined, nType: NotificationType) {
    const eventTitle = metaData?.event?.title ?? '';

    const dateFormat = 'MMMM d, y, h:mm a';
    const eventDate = metaData?.event?.dateStart ?
    formatDate(metaData.event.dateStart, dateFormat, 'en-US') : '';

    const teamName = metaData?.team?.name ?? '';
    const joinerName = metaData?.joiner?.displayName ?? '';
    const isCreator = metaData?.recipient === 'creator';
    switch (nType) {
      /** Badge Notifications */
      case NotificationType.NEW_BADGE:
        this.text = `New badge earned!`;
        this.subtext = `You're crushing it!`;
        break;
      /** Team Notifications */
      case NotificationType.TEAM_REMINDER:
        // eslint-disable-next-line max-len
        this.text = `${teamName} has an event named ${eventTitle} coming up on ${eventDate}`;
        this.subtext = `See event details...`;
        break;
      case NotificationType.TEAM_JOINER_PENDING_JOINER:
        this.text = `${teamName} wants you to join them`;
        this.subtext = 'See team details...';
        break;
      case NotificationType.TEAM_JOINER_PENDING_CREATOR:
        // eslint-disable-next-line max-len
        this.text = `${joinerName} wants to join your team name ${teamName}`;
        this.subtext = 'See team details...';
        break;
      case NotificationType.TEAM_JOINER_APPROVED:
        this.text = isCreator ?
          // eslint-disable-next-line max-len
          `${joinerName} has accepted your request to join your team named ${teamName}` :
          `${teamName} has accepted your request to join`;
        this.subtext = 'See team details...';
        break;
      case NotificationType.TEAM_JOINER_DENIED:
        this.text = isCreator ?
          // eslint-disable-next-line max-len
          `${joinerName} has denied your request to join your team named ${teamName}` :
          `${teamName} has denied your request to join`;
        this.subtext = 'See team details...';
        break;
      case NotificationType.TEAM_JOINER_DROPPED:
        this.text = isCreator ?
          // eslint-disable-next-line max-len
          `${joinerName} has dropped from your team named ${teamName}` :
          `You have been dropped from the team named ${teamName}`;
        this.subtext = 'See team details...';
        break;
      /** Message Notifications */
      case NotificationType.NEW_MESSAGE:
        const conversation = metaData?.conversation;
        const latestMessage = conversation?.getLatestMessage();
        const message = latestMessage?.text ?? '';
        // eslint-disable-next-line max-len
        this.text = `${metaData?.user?.displayName} posted a new message to ${teamName} message board: ` +
          message;
        this.subtext = 'See message details...';
        break;
      /** Event Notifications */
      case NotificationType.EVENT_JOINER_APPROVED:
        this.text = isCreator ?
          // eslint-disable-next-line max-len
          `${joinerName} has joined your event named ${eventTitle}` :
          `Your request to join the event named ${eventTitle} has been approved`;
        this.subtext = 'See event details...';
        break;
      case NotificationType.EVENT_JOINER_DENIED:
        this.text = isCreator ?
          // eslint-disable-next-line max-len
          `${joinerName} was denied to join your event named ${eventTitle}` :
          `Your request to join the event named ${eventTitle} has been denied`;
        this.subtext = 'See event details...';
        break;
      case NotificationType.EVENT_JOINER_PENDING_JOINER:
        // TODO: This is not being set properly
        this.text = `Player wants you to join them`;
        this.subtext = 'See event details...';
        break;
      case NotificationType.EVENT_JOINER_DROPPED:
        this.text = isCreator ?
          // eslint-disable-next-line max-len
          `${joinerName} has dropped from your event named ${eventTitle}` :
          `You have been dropped from the event named ${eventTitle}`;
        this.subtext = 'See event details...';
        break;
      case NotificationType.EVENT_JOINER_PENDING_CREATOR:
      case NotificationType.EVENT_JOINER_WAITLIST:
        this.text = isCreator ?
          // eslint-disable-next-line max-len
          `${joinerName} wants to join your event named ${eventTitle}` :
          // eslint-disable-next-line max-len
          `Your request to join the event named ${eventTitle} is awaiting approval`;
        this.subtext = 'See event details...';
        break;
      case NotificationType.EVENT_CANCELLED:
        this.text = `The event name ${eventTitle} has been cancelled`;
        this.subtext = 'See event details...';
        break;
      case NotificationType.EVENT_UPCOMING:
        const event = metaData?.event;
        if (event && event.dateStart) {
          const timeDiffInMs = event?.dateStart?.getTime() - new Date()?.getTime();
          const timeDiffInHours = Math.floor(timeDiffInMs / (1000 * 60 * 60));
          this.text = `${eventTitle} is coming up in ${timeDiffInHours} hours.`;
          this.subtext = 'See event details...';
        } else {
          return;
        }

        break;
      case NotificationType.EVENT_FULL:
        this.text = `The event name ${eventTitle} is full`;
        this.subtext = 'See event details...';
        break;
      /** Rating Notifications */
      case NotificationType.RATE_PLAYER:
        this.text = `Rate the event named ${eventTitle}`;
        this.subtext = 'Submit feedback';
        break;
      default: break;
    }
  }

  getText() {
    return this.text;
  }
  getSubtext() {
    return this.subtext;
  }
}

type NotificationMetadata = {
  event?: GthEventItemModel;
  joiner?: GthUserModel;
  recipient?: string;
  user?: GthUserModel;
  conversation?: GthConversationModel;
  team?: GthTeamModel;
  badge?: GthBadgeModel;
}

export class GthNotificationModel extends GthModel<Notification> {
  constructor(
    id: string,
    model: Notification,
    private textBuilder = new TextBuilder(
      model.metadata as NotificationMetadata,
      model.type,
    ),
  ) {
    super(id, model);

    if (!this.metadata) return;
    const metadata = model.metadata as NotificationMetadata;
    this._eventModel = metadata.event as GthEventItemModel;
    this._joinerModel = metadata.joiner as GthUserModel;
    this._userModel = metadata.user as GthUserModel;
    this._conversationModel = metadata.conversation as GthConversationModel;
    this._teamModel = metadata.team as GthTeamModel;
    this._badgeModel = metadata.badge as GthBadgeModel;
  }

  get createdAt(): Date {
    return this.model.createdAt;
  }

  get recipient(): 'joiner' | 'creator' | undefined {
    return this.model.metadata ? this.model.metadata['recipient'] : undefined;
  }

  get type() {
    return this.model.type;
  }

  get text(): string {
    return this.textBuilder.getText();
  }

  get subtext(): string {
    return this.textBuilder.getSubtext();
  }

  get eventModel(): GthEventItemModel | undefined {
    return this._eventModel;
  }

  get joiner() {
    return this.joinerModel ? this.joinerModel.uid : null;
  }

  get user() {
    return this.userModel ? this.userModel.uid : null;
  }

  get conversation() {
    return this.conversationModel ? this.conversationModel.id : null;
  }

  get team() {
    return this.teamModel ? this.teamModel.id : null;
  }

  get badge() {
    return this.badgeModel ? this.badgeModel.id : null;
  }

  get eventItem() {
    return this.eventModel ? this.eventModel.id : null;
  }

  get joinerModel(): GthUserModel | undefined {
    return this._joinerModel;
  }

  get userModel(): GthUserModel | undefined {
    return this._userModel;
  }

  get conversationModel(): GthConversationModel | undefined {
    return this._conversationModel;
  }

  get teamModel(): GthTeamModel | undefined {
    return this._teamModel;
  }

  get badgeModel(): GthBadgeModel | undefined {
    return this._badgeModel;
  }

  get read(): boolean {
    return this.model.read;
  }

  get id(): string {
    return this._id;
  }

  private _eventModel?: GthEventItemModel;
  private _joinerModel?: GthUserModel;
  private _userModel?: GthUserModel;
  private _conversationModel?: GthConversationModel;
  private _teamModel?: GthTeamModel;
  private _badgeModel?: GthBadgeModel;

  setEvent(event: GthEventItemModel) {
    this._eventModel = event;
  }

  setJoiner(joiner: GthUserModel) {
    this._joinerModel = joiner;
  }

  markAsRead() {
    this.model.read = true;
  }
}

@Injectable({
  providedIn: 'root',
})
export class NotificationAdapter implements Adapter<Notification> {
  adapt(item: Notification): GthNotificationModel {
    return new GthNotificationModel(item.id, item);
  }
}
