import { DataSnapshot } from '@firebase/database-types';
import firebase from 'firebase/compat/app';

import { Mapper } from '../interfaces/mapper';
import { NotificationModel } from '../models/notifications';

export class EventNotificationMapper implements Mapper<NotificationModel> {
  fromDataSnapshot(snapshot: DataSnapshot): NotificationModel {
    const data = snapshot.val();
    return new NotificationModel(
      snapshot.ref as any,

      data[NotificationModel.TYPE],
      data[NotificationModel.METADATA],
      data[NotificationModel.READ],
    );
  }

  fromSnapshot(
    snapshot: firebase.firestore.DocumentSnapshot,
  ): NotificationModel | undefined {
    if (snapshot === null || snapshot === undefined) return undefined;
    const data = snapshot.data();
    if (data === null || data === undefined) return undefined;

    return new NotificationModel(
      snapshot.ref as any,
      data[NotificationModel.TYPE],
      data[NotificationModel.METADATA],
      data[NotificationModel.READ],
    );
  }

  toMap(item: NotificationModel): firebase.firestore.DocumentData {
    const mapped = {
      [NotificationModel.TYPE]: item.type,
      [NotificationModel.METADATA]: item.metadata,
      [NotificationModel.READ]: item.read,
    };
    return mapped;
  }

  toMapWithId(item: NotificationModel) {
    const mapped = {
      id: item.ref ? item.ref.id : '',
      [NotificationModel.TYPE]: item.type,
      [NotificationModel.METADATA]: item.metadata,
      [NotificationModel.READ]: item.read,
    };
    return mapped;
  }
}
