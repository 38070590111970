import { Injectable } from '@angular/core';
import { NotificationDAOService } from '@index/daos/event-notification-dao';
import { CallableOptions, CallableRoutes, SuccessResult } from '@index/interfaces';

export { CallableOptions, CallableRoutes } from '@index/interfaces';

@Injectable({
  providedIn: 'root',
})
export class GthCallableService {
  constructor(
    readonly notifications: NotificationDAOService,
  ) { }

  /**
   * Calls a Firebase Function
   * @param {CallableOptions} options Firebase Function Options
   * @return {Object} Function Response
   */
  async call(options: CallableOptions) {
    const { route, data } = options;

    try {
      SuccessResult;
      const callable = this.getRouteMap().get(route);

      let results = await callable(data);

      if (Array.isArray(results)) {
        results = results.map((item) => {
          return {
            id: item?.id || item?.uid, data: item,
          };
        },
        );
      } else {
        if (!results) {
          return undefined;
        }
        results = {
          id: results.id ? results.id : '', data: results,
        };
      }
      return SuccessResult(results);
    } catch (e) {
      throw (e);
    }
  }

  getRouteMap() {
    return new Map<CallableRoutes, any>([
      // Notifications
      [CallableRoutes.NOTIFICATIONS_CREATE,
      this.notifications.create.bind(this.notifications)],
      [CallableRoutes.NOTIFICATIONS_LIST,
      this.notifications.list.bind(this.notifications)],
      [CallableRoutes.NOTIFICATIONS_UPDATE,
      this.notifications.update.bind(this.notifications)],
      [CallableRoutes.NOTIFICATIONS_DELETE,
      this.notifications.delete.bind(this.notifications)],
    ]);
  }
}
