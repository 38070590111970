import { Injectable } from '@angular/core';

import { GthDebuggingFunctionService } from './debugging-function.service';
import { GthNotificationFunctionService } from './notification-function.service';

@Injectable({
  providedIn: 'root',
})
export class GthCloudFunctionService {
  constructor(
    public readonly notification: GthNotificationFunctionService,
    public readonly debugging: GthDebuggingFunctionService,
  ) { }
}
